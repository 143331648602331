<template>
  <div
    class="border border-eee rounded p-20 relative"
    :class="{ card: showDel }"
  >
    <div class="absolute top-20 right-20 flex items-center" v-if="showStatus">
      <div class="text-999 text-xs mr-10">
        <span v-if="dataSource.AuditStatus === 1">待审核</span>
        <span v-if="dataSource.AuditStatus === 2">已通过</span>
        <span v-if="dataSource.AuditStatus === 3">未通过</span>
      </div>
      <div class="w-2 h-2 rounded-full" :class="color"></div>
    </div>
    <div class="ellipsis-1 font-bold text-xl mb-20">{{ dataSource.Title }}</div>
    <a-row class="text-sm text-999">
      <a-col :span="8" class="flex items-center">
        <a-icon type="environment" class="mr-1" />
        <span>地区：</span>
        <span class="text-333 ellipsis-1">{{ dataSource.Region.Name }}</span>
      </a-col>
      <a-col :span="8" class="flex items-center">
        <a-icon type="calendar" class="mr-1" />
        <span>发布时间：</span>
        <span class="text-333">{{
          dataSource.PublicTime | formatterTime("YYYY-MM-DD")
        }}</span>
      </a-col>
      <a-col :span="8" class="flex items-center">
        <img src="@/assets/icons/type.svg" class="mr-1" />
        <span>政策类型：</span>
        <span class="text-333">{{ dataSource.Type.Name }}</span>
      </a-col>
    </a-row>

    <div class="absolute right-0 bottom-0 del">
      <a-popconfirm
        title="是否删除此政策?"
        ok-text="删除"
        cancel-text="取消"
        @confirm="del"
      >
        <a-button type="danger" @click.stop="">删除</a-button>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import { deletePolicy } from "@/api/api/policy";
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    showDel: Boolean,
  },
  computed: {
    color() {
      if (this.dataSource.AuditStatus === 1) {
        return "first";
      } else if (this.dataSource.AuditStatus === 2) {
        return "finish";
      } else if (this.dataSource.AuditStatus === 3) {
        return "reject";
      }
    },
  },
  methods: {
    del() {
      return deletePolicy(this.dataSource.ID).then(() => {
        this.$emit("del");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.first {
  background-color: #2e7ced;
}
.finish {
  background-color: #52c42b;
}
.reject {
  background-color: #d93434;
}
.del {
  display: none;
}
.card:hover .del {
  display: inline-block;
}
</style>