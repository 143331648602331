// 沙龙活动
import request from '@/utils/request1'
import { transformPolicyReview, transformPolicy } from '@/utils/transform/policy.js'

// 管理员获取政策列表
export function getPolicyList(params) {
    return request.get(`/op/policies`, { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformPolicy)
        }
        return Promise.resolve(temp)
    })
}

// 管理员获取政策留言审核列表
export function getPolicyReviewList(params) {
    return request.get(`/op/policy_reviews`, { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformPolicyReview)
        }
        return Promise.resolve(temp)
    })
}

// 获取我的政策
export function getMyPolicyList(params) {
    return request.get(`/user/policies`, { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformPolicy)
        }
        return Promise.resolve(temp)
    })
}

// 获取我参与的
export function getJoinedPolicyList(params) {
    return request.get(`/user/policies/participated`, { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformPolicy)
        }
        return Promise.resolve(temp)
    })
}

// 获取政策公开列表
export function getPublicPolicyList(params) {
    return request.get(`/policies`, { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformPolicy)
        }
        return Promise.resolve(temp)
    })
}

// 创建政策
export function createPolicy(data) {
    return request.post(`/policies`, data)
}

// 获取公开政策详情
export function getPublicPolicy(id) {
    return request.get(`/policies/${id}`).then(({ data = {} }) => {
        return Promise.resolve(transformPolicy(data))
    })
}

// 获取政策详情
export function getPolicy(id) {
    return request.get(`/policies/${id}/draft`).then(({ data = {} }) => {
        return Promise.resolve(transformPolicy(data))
    })
}

// 政策审核
export function evaluatePolicy(id, data) {
    return request.post(`/op/policies/${id}/audit`, data)
}

// 政策留言审核
export function evaluatePolicyReview(id, data) {
    return request.post(`op/policy_reviews/${id}/audit`, data)
}

// 政策留言列表
export function getPolicyReview(id, params) {
    return request.get(`/policies/${id}/reviews`, { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformPolicyReview)
        }
        return Promise.resolve(temp)
    })
}

// 创建政策留言
export function createPolicyReview(id, data) {
    return request.post(`/policies/${id}/reviews`, data)
}

// 获取我的政策留言
export function getMyParticipants(id) {
    return request.get(`/policies/${id}/participants/0`)
}

// 参与政策调研
export function join(id, data) {
    return request.post(`/policies/${id}/participants`, data)
}

export function getParticipants(id, params) {
    return request.get(`/policies/${id}/participants`, { params })
}

// 提交政策
export function submitPolicy(id) {
    return request.post(`/policies/${id}/submit`)
}

export function finishPolicy(id, data) {
    return request.post(`/policies/${id}/finish`, data)
}

export function updateOpPolicy(id, data) {
    return request.put(`/op/policies/${id}`, data)
}

export function updatePolicy(id, data) {
    return request.put(`/policies/${id}`, data)
}

export function deletePolicy(id){
    return request.delete(`/policies/${id}`)
}