import {
    transformOptions,
    transformAttachment,
    getTime
} from './_util'

export function transformPolicy(data) {
    const detail = data.Detail || {},
        Region = transformOptions(detail.Region, 'PolicyRegion') || {},
        Type = transformOptions(detail.Type, 'PolicyType') || {};
    const temp = {
        ID: data.ID,
        UID: data.UID,
        EvaluationStatus: data.EvaluationStatus,
        AuditStatus: data.AuditStatus,
        Participants: data.Participants,
        Reviews: data.Reviews,
        Visible: data.Visible,
        Feedback: data.Feedback,
        CreatedAt: new Date(data.CreatedAt).getTime(),
        SubmittedAt: new Date(data.SubmittedAt).getTime(),
        Attachments: transformAttachment(detail.Attachments) || [],
        Desc: detail.Desc,
        PublicTime: new Date(detail.PublicTime).getTime(),
        Region,
        Type,
        TypeId: Type.ID,
        regionId: Region.ID,
        Target: detail.Target,
        Rewards: detail.Rewards,
        Title: detail.Title,
        Source: detail.Source,
    }
    return temp;
}

export function transformPolicyReview(data) {
    const detail = data.Policy || {},
        comment = data.Detail || {},
        company = data.Company || {},
        info = company.Info || {};
    const temp = {
        ID: data.ID,
        AuditStatus: data.AuditStatus,
        Participants: data.Participants,
        Reviews: data.Reviews,
        Visible: data.Visible,
        Feedback: data.Feedback,
        CreatedAt: new Date(data.CreatedAt).getTime(),
        SubmittedAt: new Date(data.SubmittedAt).getTime(),
        Attachments: transformAttachment(detail.Attachments) || [],
        Desc: detail.Desc,
        PublicTime: new Date(detail.PublicTime).getTime(),
        Region: transformOptions(detail.Region, 'PolicyRegion') || {},
        Type: transformOptions(detail.Type, 'PolicyType') || {},
        Target: detail.Target,
        Rewards: detail.Rewards,
        Title: detail.Title,
        Source: detail.Source,
        Content: comment.Content,
        PolicyID: data.PolicyID,
        companyName: info.Name,
        companyLogo: transformAttachment(info.Logo) || {},
    }
    return temp
}